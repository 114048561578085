*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.top-28 {
  top: 7rem;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.top-\[40px\] {
  top: 40px;
}

.left-\[-2\.7rem\] {
  left: -2.7rem;
}

.right-6 {
  right: 1.5rem;
}

.-right-\[100\%\] {
  right: -100%;
}

.top-24 {
  top: 6rem;
}

.right-0 {
  right: 0;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-\[20\] {
  z-index: 20;
}

.z-\[89\] {
  z-index: 89;
}

.z-\[70\] {
  z-index: 70;
}

.z-\[99\] {
  z-index: 99;
}

.z-\[999\] {
  z-index: 999;
}

.m-4 {
  margin: 1rem;
}

.m-14 {
  margin: 3.5rem;
}

.m-2 {
  margin: .5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.mb-60 {
  margin-bottom: 15rem;
}

.mt-33vh {
  margin-top: 33vh;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-14 {
  height: 3.5rem;
}

.h-10 {
  height: 2.5rem;
}

.h-full {
  height: 100%;
}

.h-20 {
  height: 5rem;
}

.h-52 {
  height: 13rem;
}

.h-12 {
  height: 3rem;
}

.h-6 {
  height: 1.5rem;
}

.h-auto {
  height: auto;
}

.h-24 {
  height: 6rem;
}

.h-8 {
  height: 2rem;
}

.h-\[50px\] {
  height: 50px;
}

.h-screen {
  height: 100vh;
}

.h-56 {
  height: 14rem;
}

.h-33vh {
  height: 33vh;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-64 {
  height: 16rem;
}

.h-16 {
  height: 4rem;
}

.h-72 {
  height: 18rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-\[45vh\] {
  max-height: 45vh;
}

.max-h-70vh {
  max-height: 70vh;
}

.max-h-42vh {
  max-height: 42vh;
}

.w-full {
  width: 100%;
}

.w-10 {
  width: 2.5rem;
}

.w-1\/2 {
  width: 50%;
}

.w-12 {
  width: 3rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[50px\] {
  width: 50px;
}

.\!w-8 {
  width: 2rem !important;
}

.w-64 {
  width: 16rem;
}

.w-24 {
  width: 6rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-screen {
  width: 100vw;
}

.w-72 {
  width: 18rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-10 {
  --tw-translate-x: -2.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[0\.5rem\] {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[3\.8rem\] {
  --tw-translate-y: 3.8rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[7\.1rem\] {
  --tw-translate-y: 7.1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[10\.4rem\] {
  --tw-translate-y: 10.4rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-24 {
  --tw-translate-y: 6rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes in-and-out {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  15% {
    opacity: 1;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.animate-in-and-out {
  animation: 8s ease-in-out infinite in-and-out;
}

@keyframes moving-arrow {
  0% {
    opacity: 0;
    transform: translateY(.875rem);
  }

  10% {
    opacity: 1;
    transform: translateY(.875rem);
  }

  20% {
    opacity: 1;
    transform: translateY(.875rem);
  }

  30% {
    opacity: 1;
    transform: translateY(4.125rem);
  }

  40% {
    opacity: 1;
    transform: translateY(4.125rem);
  }

  50% {
    opacity: 1;
    transform: translateY(7.375rem);
  }

  60% {
    opacity: 1;
    transform: translateY(7.375rem);
  }

  70% {
    opacity: 1;
    transform: translateY(10.625rem);
  }

  80% {
    opacity: 1;
    transform: translateY(10.625rem);
  }

  95% {
    opacity: 1;
    transform: translateY(10.625rem);
  }

  100% {
    opacity: 0;
    transform: translateY(10.625rem);
  }
}

.animate-moving-arrow {
  animation: 8s ease-in-out infinite moving-arrow;
}

@keyframes second-arrow {
  0% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animate-second-arrow {
  animation: 14s ease-in-out infinite second-arrow;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.cursor-pointer {
  cursor: pointer;
}

.resize-none {
  resize: none;
}

.appearance-none {
  appearance: none;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-12 {
  gap: 3rem;
}

.gap-4 {
  gap: 1rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)) );
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.border {
  border-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-transparent {
  border-color: #0000;
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(0 161 255 / var(--tw-border-opacity));
}

.border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(24 26 30 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 161 255 / var(--tw-bg-opacity));
}

.bg-mid {
  --tw-bg-opacity: 1;
  background-color: rgb(149 149 149 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-lightblue {
  --tw-bg-opacity: 1;
  background-color: rgb(184 232 253 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-gold {
  --tw-bg-opacity: 1;
  background-color: rgb(219 150 53 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-blue-500 {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-to: #3b82f600;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-blue-600 {
  --tw-gradient-to: #2563eb;
}

.fill-blue-600 {
  fill: #2563eb;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-left {
  object-position: left;
}

.p-6 {
  padding: 1.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.p-0 {
  padding: 0;
}

.p-8 {
  padding: 2rem;
}

.p-3 {
  padding: .75rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-baseline {
  vertical-align: baseline;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-tight {
  line-height: 1.25;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(0 161 255 / var(--tw-text-opacity));
}

.text-grayish {
  --tw-text-opacity: 1;
  color: rgb(94 94 94 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-mid {
  --tw-text-opacity: 1;
  color: rgb(149 149 149 / var(--tw-text-opacity));
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(24 26 30 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.opacity-0 {
  opacity: 0;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.hover\:from-blue-600:hover {
  --tw-gradient-from: #2563eb;
  --tw-gradient-to: #2563eb00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.hover\:to-blue-700:hover {
  --tw-gradient-to: #1d4ed8;
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.focus\:border-green-700:focus {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

@media (min-width: 640px) {
  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:leading-5 {
    line-height: 1.25rem;
  }
}

@media (min-height: 800px) {
  .md\:max-h-50vh {
    max-height: 50vh;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.max-h-60vh {
  max-height: 60vh;
}

body {
  background-color: #b8e8fd;
}

.fib {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.fi {
  width: 1.33333em;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  line-height: 1em;
  display: inline-block;
  position: relative;
}

.fi:before {
  content: " ";
}

.fi.fis {
  width: 1em;
}

.fi-xx {
  background-image: url("xx.9cd256b0.svg");
}

.fi-xx.fis {
  background-image: url("xx.ea6b4633.svg");
}

.fi-ad {
  background-image: url("ad.7775717d.svg");
}

.fi-ad.fis {
  background-image: url("ad.49ab063c.svg");
}

.fi-ae {
  background-image: url("ae.7ccc16f1.svg");
}

.fi-ae.fis {
  background-image: url("ae.1cc9bd0b.svg");
}

.fi-af {
  background-image: url("af.ddc74831.svg");
}

.fi-af.fis {
  background-image: url("af.5c6cde28.svg");
}

.fi-ag {
  background-image: url("ag.e388c93c.svg");
}

.fi-ag.fis {
  background-image: url("ag.d1416e74.svg");
}

.fi-ai {
  background-image: url("ai.3f26d1f3.svg");
}

.fi-ai.fis {
  background-image: url("ai.97a46902.svg");
}

.fi-al {
  background-image: url("al.58c28a27.svg");
}

.fi-al.fis {
  background-image: url("al.b8ea72de.svg");
}

.fi-am {
  background-image: url("am.bf8b95ef.svg");
}

.fi-am.fis {
  background-image: url("am.50f5f7a6.svg");
}

.fi-ao {
  background-image: url("ao.df1c07b4.svg");
}

.fi-ao.fis {
  background-image: url("ao.c28406d4.svg");
}

.fi-aq {
  background-image: url("aq.ac80fa39.svg");
}

.fi-aq.fis {
  background-image: url("aq.70374d49.svg");
}

.fi-ar {
  background-image: url("ar.58d26ccd.svg");
}

.fi-ar.fis {
  background-image: url("ar.f6222d75.svg");
}

.fi-as {
  background-image: url("as.f9e53e60.svg");
}

.fi-as.fis {
  background-image: url("as.25a7a2bb.svg");
}

.fi-at {
  background-image: url("at.db6682e7.svg");
}

.fi-at.fis {
  background-image: url("at.b3805e5a.svg");
}

.fi-au {
  background-image: url("au.a49450d4.svg");
}

.fi-au.fis {
  background-image: url("au.2590047c.svg");
}

.fi-aw {
  background-image: url("aw.1c2277ab.svg");
}

.fi-aw.fis {
  background-image: url("aw.8653eb2a.svg");
}

.fi-ax {
  background-image: url("ax.6f8be544.svg");
}

.fi-ax.fis {
  background-image: url("ax.4828b04c.svg");
}

.fi-az {
  background-image: url("az.192ec897.svg");
}

.fi-az.fis {
  background-image: url("az.a2e68fe8.svg");
}

.fi-ba {
  background-image: url("ba.324de471.svg");
}

.fi-ba.fis {
  background-image: url("ba.533a942c.svg");
}

.fi-bb {
  background-image: url("bb.5cb28434.svg");
}

.fi-bb.fis {
  background-image: url("bb.ddda2f66.svg");
}

.fi-bd {
  background-image: url("bd.70f44c84.svg");
}

.fi-bd.fis {
  background-image: url("bd.8a64cf3c.svg");
}

.fi-be {
  background-image: url("be.6ee46497.svg");
}

.fi-be.fis {
  background-image: url("be.236c336e.svg");
}

.fi-bf {
  background-image: url("bf.1f00e179.svg");
}

.fi-bf.fis {
  background-image: url("bf.9d537d7f.svg");
}

.fi-bg {
  background-image: url("bg.6443f9a5.svg");
}

.fi-bg.fis {
  background-image: url("bg.e14be734.svg");
}

.fi-bh {
  background-image: url("bh.6f84fd11.svg");
}

.fi-bh.fis {
  background-image: url("bh.681a73e8.svg");
}

.fi-bi {
  background-image: url("bi.e8fc5b93.svg");
}

.fi-bi.fis {
  background-image: url("bi.9124bba4.svg");
}

.fi-bj {
  background-image: url("bj.6b5d9b74.svg");
}

.fi-bj.fis {
  background-image: url("bj.091b14ff.svg");
}

.fi-bl {
  background-image: url("bl.09c59fb3.svg");
}

.fi-bl.fis {
  background-image: url("bl.2360ab87.svg");
}

.fi-bm {
  background-image: url("bm.c25de31d.svg");
}

.fi-bm.fis {
  background-image: url("bm.94e5b00f.svg");
}

.fi-bn {
  background-image: url("bn.2e211fe1.svg");
}

.fi-bn.fis {
  background-image: url("bn.35e2d5b7.svg");
}

.fi-bo {
  background-image: url("bo.0904c8cf.svg");
}

.fi-bo.fis {
  background-image: url("bo.140d6a33.svg");
}

.fi-bq {
  background-image: url("bq.8464e676.svg");
}

.fi-bq.fis {
  background-image: url("bq.c53c1132.svg");
}

.fi-br {
  background-image: url("br.2da65e6d.svg");
}

.fi-br.fis {
  background-image: url("br.8c20c50b.svg");
}

.fi-bs {
  background-image: url("bs.6a66f029.svg");
}

.fi-bs.fis {
  background-image: url("bs.53dd893f.svg");
}

.fi-bt {
  background-image: url("bt.7e57fb74.svg");
}

.fi-bt.fis {
  background-image: url("bt.be21709e.svg");
}

.fi-bv {
  background-image: url("bv.7cb736a9.svg");
}

.fi-bv.fis {
  background-image: url("bv.4583089c.svg");
}

.fi-bw {
  background-image: url("bw.4ff6dc63.svg");
}

.fi-bw.fis {
  background-image: url("bw.d98be72b.svg");
}

.fi-by {
  background-image: url("by.bcec9583.svg");
}

.fi-by.fis {
  background-image: url("by.d961c1d5.svg");
}

.fi-bz {
  background-image: url("bz.a5b19791.svg");
}

.fi-bz.fis {
  background-image: url("bz.f4f40753.svg");
}

.fi-ca {
  background-image: url("ca.c5ff56b9.svg");
}

.fi-ca.fis {
  background-image: url("ca.937be4c1.svg");
}

.fi-cc {
  background-image: url("cc.b07be0c8.svg");
}

.fi-cc.fis {
  background-image: url("cc.c4f2d4b0.svg");
}

.fi-cd {
  background-image: url("cd.e99aadda.svg");
}

.fi-cd.fis {
  background-image: url("cd.fa415aaf.svg");
}

.fi-cf {
  background-image: url("cf.942bee00.svg");
}

.fi-cf.fis {
  background-image: url("cf.a07f4fed.svg");
}

.fi-cg {
  background-image: url("cg.d411bc31.svg");
}

.fi-cg.fis {
  background-image: url("cg.92fde35d.svg");
}

.fi-ch {
  background-image: url("ch.014e2a1e.svg");
}

.fi-ch.fis {
  background-image: url("ch.38a369d2.svg");
}

.fi-ci {
  background-image: url("ci.7a2bda97.svg");
}

.fi-ci.fis {
  background-image: url("ci.f35efd2b.svg");
}

.fi-ck {
  background-image: url("ck.9bee55a4.svg");
}

.fi-ck.fis {
  background-image: url("ck.1fd253db.svg");
}

.fi-cl {
  background-image: url("cl.1c2fa9fa.svg");
}

.fi-cl.fis {
  background-image: url("cl.70cf0887.svg");
}

.fi-cm {
  background-image: url("cm.a4f8434c.svg");
}

.fi-cm.fis {
  background-image: url("cm.fb65b5d0.svg");
}

.fi-cn {
  background-image: url("cn.c4b72974.svg");
}

.fi-cn.fis {
  background-image: url("cn.d81d97c2.svg");
}

.fi-co {
  background-image: url("co.3a0e8b41.svg");
}

.fi-co.fis {
  background-image: url("co.c4003ec8.svg");
}

.fi-cr {
  background-image: url("cr.13943474.svg");
}

.fi-cr.fis {
  background-image: url("cr.d4d0c6c3.svg");
}

.fi-cu {
  background-image: url("cu.0de7db8f.svg");
}

.fi-cu.fis {
  background-image: url("cu.4761cd0c.svg");
}

.fi-cv {
  background-image: url("cv.cf443876.svg");
}

.fi-cv.fis {
  background-image: url("cv.b1331597.svg");
}

.fi-cw {
  background-image: url("cw.7b6bd0fb.svg");
}

.fi-cw.fis {
  background-image: url("cw.ce7d6242.svg");
}

.fi-cx {
  background-image: url("cx.f3e47c14.svg");
}

.fi-cx.fis {
  background-image: url("cx.1cd77e8a.svg");
}

.fi-cy {
  background-image: url("cy.531e91e2.svg");
}

.fi-cy.fis {
  background-image: url("cy.7a877447.svg");
}

.fi-cz {
  background-image: url("cz.98bd2914.svg");
}

.fi-cz.fis {
  background-image: url("cz.893d75a7.svg");
}

.fi-de {
  background-image: url("de.67078f90.svg");
}

.fi-de.fis {
  background-image: url("de.de8e6338.svg");
}

.fi-dj {
  background-image: url("dj.23810831.svg");
}

.fi-dj.fis {
  background-image: url("dj.f614db67.svg");
}

.fi-dk {
  background-image: url("dk.8633dfed.svg");
}

.fi-dk.fis {
  background-image: url("dk.2af4e8bb.svg");
}

.fi-dm {
  background-image: url("dm.51ec0ea6.svg");
}

.fi-dm.fis {
  background-image: url("dm.737a54a4.svg");
}

.fi-do {
  background-image: url("do.51eb8860.svg");
}

.fi-do.fis {
  background-image: url("do.08bb5a94.svg");
}

.fi-dz {
  background-image: url("dz.f127e3b5.svg");
}

.fi-dz.fis {
  background-image: url("dz.e790f805.svg");
}

.fi-ec {
  background-image: url("ec.feedb5f7.svg");
}

.fi-ec.fis {
  background-image: url("ec.4a7b01c6.svg");
}

.fi-ee {
  background-image: url("ee.1ecd2095.svg");
}

.fi-ee.fis {
  background-image: url("ee.0a827109.svg");
}

.fi-eg {
  background-image: url("eg.2ba9482a.svg");
}

.fi-eg.fis {
  background-image: url("eg.92323595.svg");
}

.fi-eh {
  background-image: url("eh.2898cda6.svg");
}

.fi-eh.fis {
  background-image: url("eh.37b8d736.svg");
}

.fi-er {
  background-image: url("er.a333da28.svg");
}

.fi-er.fis {
  background-image: url("er.def8b033.svg");
}

.fi-es {
  background-image: url("es.1a700169.svg");
}

.fi-es.fis {
  background-image: url("es.f2fca40f.svg");
}

.fi-et {
  background-image: url("et.f79d03b6.svg");
}

.fi-et.fis {
  background-image: url("et.d37deee1.svg");
}

.fi-fi {
  background-image: url("fi.e79a6beb.svg");
}

.fi-fi.fis {
  background-image: url("fi.74ba1a57.svg");
}

.fi-fj {
  background-image: url("fj.8890bd94.svg");
}

.fi-fj.fis {
  background-image: url("fj.53fc9c4e.svg");
}

.fi-fk {
  background-image: url("fk.0f9a86f3.svg");
}

.fi-fk.fis {
  background-image: url("fk.7bba3568.svg");
}

.fi-fm {
  background-image: url("fm.16b167d9.svg");
}

.fi-fm.fis {
  background-image: url("fm.8906bdc0.svg");
}

.fi-fo {
  background-image: url("fo.528f9481.svg");
}

.fi-fo.fis {
  background-image: url("fo.9c9049e0.svg");
}

.fi-fr {
  background-image: url("fr.2e87749c.svg");
}

.fi-fr.fis {
  background-image: url("fr.919efe22.svg");
}

.fi-ga {
  background-image: url("ga.ba4041ff.svg");
}

.fi-ga.fis {
  background-image: url("ga.adf08f4b.svg");
}

.fi-gb {
  background-image: url("gb.81503221.svg");
}

.fi-gb.fis {
  background-image: url("gb.2981d653.svg");
}

.fi-gd {
  background-image: url("gd.b7da95d5.svg");
}

.fi-gd.fis {
  background-image: url("gd.4d37b9d1.svg");
}

.fi-ge {
  background-image: url("ge.652c7a70.svg");
}

.fi-ge.fis {
  background-image: url("ge.0b3e78fd.svg");
}

.fi-gf {
  background-image: url("gf.ac354448.svg");
}

.fi-gf.fis {
  background-image: url("gf.534d56b3.svg");
}

.fi-gg {
  background-image: url("gg.d7b902b5.svg");
}

.fi-gg.fis {
  background-image: url("gg.08a465ff.svg");
}

.fi-gh {
  background-image: url("gh.1963d7b3.svg");
}

.fi-gh.fis {
  background-image: url("gh.d2d8b082.svg");
}

.fi-gi {
  background-image: url("gi.83cbada3.svg");
}

.fi-gi.fis {
  background-image: url("gi.7f570f25.svg");
}

.fi-gl {
  background-image: url("gl.24a93430.svg");
}

.fi-gl.fis {
  background-image: url("gl.a03ea792.svg");
}

.fi-gm {
  background-image: url("gm.c19d36c5.svg");
}

.fi-gm.fis {
  background-image: url("gm.5b7d86ce.svg");
}

.fi-gn {
  background-image: url("gn.7c6f4c45.svg");
}

.fi-gn.fis {
  background-image: url("gn.85464138.svg");
}

.fi-gp {
  background-image: url("gp.8b34a164.svg");
}

.fi-gp.fis {
  background-image: url("gp.5163edac.svg");
}

.fi-gq {
  background-image: url("gq.148f8cae.svg");
}

.fi-gq.fis {
  background-image: url("gq.1236844d.svg");
}

.fi-gr {
  background-image: url("gr.c2162bf3.svg");
}

.fi-gr.fis {
  background-image: url("gr.7976ee0f.svg");
}

.fi-gs {
  background-image: url("gs.a2cebbf9.svg");
}

.fi-gs.fis {
  background-image: url("gs.0286a303.svg");
}

.fi-gt {
  background-image: url("gt.b8416980.svg");
}

.fi-gt.fis {
  background-image: url("gt.11299118.svg");
}

.fi-gu {
  background-image: url("gu.3957f8df.svg");
}

.fi-gu.fis {
  background-image: url("gu.f7bd7f57.svg");
}

.fi-gw {
  background-image: url("gw.309b3dd7.svg");
}

.fi-gw.fis {
  background-image: url("gw.edcb8189.svg");
}

.fi-gy {
  background-image: url("gy.c0f8ae9c.svg");
}

.fi-gy.fis {
  background-image: url("gy.41a91823.svg");
}

.fi-hk {
  background-image: url("hk.520acc0b.svg");
}

.fi-hk.fis {
  background-image: url("hk.4997f67b.svg");
}

.fi-hm {
  background-image: url("hm.b5d24bd1.svg");
}

.fi-hm.fis {
  background-image: url("hm.e7d0dd03.svg");
}

.fi-hn {
  background-image: url("hn.8426d943.svg");
}

.fi-hn.fis {
  background-image: url("hn.9f08d62e.svg");
}

.fi-hr {
  background-image: url("hr.d61c6ef0.svg");
}

.fi-hr.fis {
  background-image: url("hr.5ead1bed.svg");
}

.fi-ht {
  background-image: url("ht.3c386c7a.svg");
}

.fi-ht.fis {
  background-image: url("ht.d244cb6a.svg");
}

.fi-hu {
  background-image: url("hu.9b0feb03.svg");
}

.fi-hu.fis {
  background-image: url("hu.87941697.svg");
}

.fi-id {
  background-image: url("id.7a6b571f.svg");
}

.fi-id.fis {
  background-image: url("id.2b8e32f6.svg");
}

.fi-ie {
  background-image: url("ie.1d60d1c9.svg");
}

.fi-ie.fis {
  background-image: url("ie.4223a380.svg");
}

.fi-il {
  background-image: url("il.dde9a54b.svg");
}

.fi-il.fis {
  background-image: url("il.bee86745.svg");
}

.fi-im {
  background-image: url("im.b5ce972c.svg");
}

.fi-im.fis {
  background-image: url("im.95d5b06d.svg");
}

.fi-in {
  background-image: url("in.f6b280b9.svg");
}

.fi-in.fis {
  background-image: url("in.f377833a.svg");
}

.fi-io {
  background-image: url("io.5d6889c9.svg");
}

.fi-io.fis {
  background-image: url("io.40102312.svg");
}

.fi-iq {
  background-image: url("iq.b93d78a9.svg");
}

.fi-iq.fis {
  background-image: url("iq.b5d1d946.svg");
}

.fi-ir {
  background-image: url("ir.66746ff5.svg");
}

.fi-ir.fis {
  background-image: url("ir.dfc6e4e7.svg");
}

.fi-is {
  background-image: url("is.a4b3e544.svg");
}

.fi-is.fis {
  background-image: url("is.3511dd6c.svg");
}

.fi-it {
  background-image: url("it.c817cf75.svg");
}

.fi-it.fis {
  background-image: url("it.0a52ceb7.svg");
}

.fi-je {
  background-image: url("je.e90b8648.svg");
}

.fi-je.fis {
  background-image: url("je.4daa5d6a.svg");
}

.fi-jm {
  background-image: url("jm.bd993bfb.svg");
}

.fi-jm.fis {
  background-image: url("jm.2deaf01d.svg");
}

.fi-jo {
  background-image: url("jo.ee347538.svg");
}

.fi-jo.fis {
  background-image: url("jo.51614690.svg");
}

.fi-jp {
  background-image: url("jp.192dec0b.svg");
}

.fi-jp.fis {
  background-image: url("jp.c0e58f7a.svg");
}

.fi-ke {
  background-image: url("ke.fefda3cc.svg");
}

.fi-ke.fis {
  background-image: url("ke.0da15cb8.svg");
}

.fi-kg {
  background-image: url("kg.c5795864.svg");
}

.fi-kg.fis {
  background-image: url("kg.e480b9ce.svg");
}

.fi-kh {
  background-image: url("kh.8911571b.svg");
}

.fi-kh.fis {
  background-image: url("kh.0b6572e2.svg");
}

.fi-ki {
  background-image: url("ki.3947ad52.svg");
}

.fi-ki.fis {
  background-image: url("ki.21881eb8.svg");
}

.fi-km {
  background-image: url("km.0713f8f1.svg");
}

.fi-km.fis {
  background-image: url("km.c9b486b6.svg");
}

.fi-kn {
  background-image: url("kn.5dd5a3a5.svg");
}

.fi-kn.fis {
  background-image: url("kn.b47c2c50.svg");
}

.fi-kp {
  background-image: url("kp.4acea034.svg");
}

.fi-kp.fis {
  background-image: url("kp.63c88299.svg");
}

.fi-kr {
  background-image: url("kr.b7191942.svg");
}

.fi-kr.fis {
  background-image: url("kr.de93a030.svg");
}

.fi-kw {
  background-image: url("kw.58c55e9f.svg");
}

.fi-kw.fis {
  background-image: url("kw.326f752c.svg");
}

.fi-ky {
  background-image: url("ky.4d985bf9.svg");
}

.fi-ky.fis {
  background-image: url("ky.4e2c2c39.svg");
}

.fi-kz {
  background-image: url("kz.34f6948a.svg");
}

.fi-kz.fis {
  background-image: url("kz.fb167b2b.svg");
}

.fi-la {
  background-image: url("la.625050a5.svg");
}

.fi-la.fis {
  background-image: url("la.7f3c6208.svg");
}

.fi-lb {
  background-image: url("lb.5942e615.svg");
}

.fi-lb.fis {
  background-image: url("lb.a976d3e3.svg");
}

.fi-lc {
  background-image: url("lc.d06886d9.svg");
}

.fi-lc.fis {
  background-image: url("lc.20446b27.svg");
}

.fi-li {
  background-image: url("li.20387ed6.svg");
}

.fi-li.fis {
  background-image: url("li.5a93c257.svg");
}

.fi-lk {
  background-image: url("lk.f8887222.svg");
}

.fi-lk.fis {
  background-image: url("lk.4480346a.svg");
}

.fi-lr {
  background-image: url("lr.6a19950f.svg");
}

.fi-lr.fis {
  background-image: url("lr.0aced674.svg");
}

.fi-ls {
  background-image: url("ls.3ba49ad2.svg");
}

.fi-ls.fis {
  background-image: url("ls.d14c95bc.svg");
}

.fi-lt {
  background-image: url("lt.a0e62637.svg");
}

.fi-lt.fis {
  background-image: url("lt.180c0d6e.svg");
}

.fi-lu {
  background-image: url("lu.28945b16.svg");
}

.fi-lu.fis {
  background-image: url("lu.d7570e0f.svg");
}

.fi-lv {
  background-image: url("lv.c352f61b.svg");
}

.fi-lv.fis {
  background-image: url("lv.20dccc8e.svg");
}

.fi-ly {
  background-image: url("ly.f266fe65.svg");
}

.fi-ly.fis {
  background-image: url("ly.0ffbc822.svg");
}

.fi-ma {
  background-image: url("ma.718fdfe4.svg");
}

.fi-ma.fis {
  background-image: url("ma.f683b984.svg");
}

.fi-mc {
  background-image: url("mc.244fc61a.svg");
}

.fi-mc.fis {
  background-image: url("mc.69f137c3.svg");
}

.fi-md {
  background-image: url("md.2d053951.svg");
}

.fi-md.fis {
  background-image: url("md.7ee6a279.svg");
}

.fi-me {
  background-image: url("me.0f02f240.svg");
}

.fi-me.fis {
  background-image: url("me.265af2d5.svg");
}

.fi-mf {
  background-image: url("mf.f5ef54a6.svg");
}

.fi-mf.fis {
  background-image: url("mf.aa84ad0e.svg");
}

.fi-mg {
  background-image: url("mg.10e26889.svg");
}

.fi-mg.fis {
  background-image: url("mg.cc925d36.svg");
}

.fi-mh {
  background-image: url("mh.c53b98f3.svg");
}

.fi-mh.fis {
  background-image: url("mh.bbcb6452.svg");
}

.fi-mk {
  background-image: url("mk.e36a8880.svg");
}

.fi-mk.fis {
  background-image: url("mk.5930b5e1.svg");
}

.fi-ml {
  background-image: url("ml.dfc24ef9.svg");
}

.fi-ml.fis {
  background-image: url("ml.23910d02.svg");
}

.fi-mm {
  background-image: url("mm.941f9144.svg");
}

.fi-mm.fis {
  background-image: url("mm.5e542ace.svg");
}

.fi-mn {
  background-image: url("mn.d24048cc.svg");
}

.fi-mn.fis {
  background-image: url("mn.903b2cfe.svg");
}

.fi-mo {
  background-image: url("mo.1cc43f2f.svg");
}

.fi-mo.fis {
  background-image: url("mo.aeebde8c.svg");
}

.fi-mp {
  background-image: url("mp.dfd746ff.svg");
}

.fi-mp.fis {
  background-image: url("mp.381677d8.svg");
}

.fi-mq {
  background-image: url("mq.689ea879.svg");
}

.fi-mq.fis {
  background-image: url("mq.f5cc64cb.svg");
}

.fi-mr {
  background-image: url("mr.5d47b80b.svg");
}

.fi-mr.fis {
  background-image: url("mr.b3d51100.svg");
}

.fi-ms {
  background-image: url("ms.191a4f59.svg");
}

.fi-ms.fis {
  background-image: url("ms.dee5c7e8.svg");
}

.fi-mt {
  background-image: url("mt.a33758bc.svg");
}

.fi-mt.fis {
  background-image: url("mt.e62db0dd.svg");
}

.fi-mu {
  background-image: url("mu.a86f9c39.svg");
}

.fi-mu.fis {
  background-image: url("mu.e8afd345.svg");
}

.fi-mv {
  background-image: url("mv.9bcf04b5.svg");
}

.fi-mv.fis {
  background-image: url("mv.84d25dac.svg");
}

.fi-mw {
  background-image: url("mw.7de0e00c.svg");
}

.fi-mw.fis {
  background-image: url("mw.5ffcedc0.svg");
}

.fi-mx {
  background-image: url("mx.6581c16a.svg");
}

.fi-mx.fis {
  background-image: url("mx.315f88ff.svg");
}

.fi-my {
  background-image: url("my.d347d474.svg");
}

.fi-my.fis {
  background-image: url("my.7b11fd3a.svg");
}

.fi-mz {
  background-image: url("mz.ef1e65e1.svg");
}

.fi-mz.fis {
  background-image: url("mz.6e9af728.svg");
}

.fi-na {
  background-image: url("na.cac3d901.svg");
}

.fi-na.fis {
  background-image: url("na.caebadfe.svg");
}

.fi-nc {
  background-image: url("nc.d28b98aa.svg");
}

.fi-nc.fis {
  background-image: url("nc.c805901b.svg");
}

.fi-ne {
  background-image: url("ne.04207781.svg");
}

.fi-ne.fis {
  background-image: url("ne.c08a16da.svg");
}

.fi-nf {
  background-image: url("nf.173a1e7e.svg");
}

.fi-nf.fis {
  background-image: url("nf.0f0a65f1.svg");
}

.fi-ng {
  background-image: url("ng.eccc75e5.svg");
}

.fi-ng.fis {
  background-image: url("ng.5b529ec3.svg");
}

.fi-ni {
  background-image: url("ni.7bb6c68f.svg");
}

.fi-ni.fis {
  background-image: url("ni.58a96e40.svg");
}

.fi-nl {
  background-image: url("nl.240773e6.svg");
}

.fi-nl.fis {
  background-image: url("nl.f8a8ba59.svg");
}

.fi-no {
  background-image: url("no.2269b783.svg");
}

.fi-no.fis {
  background-image: url("no.d88e698d.svg");
}

.fi-np {
  background-image: url("np.6231289e.svg");
}

.fi-np.fis {
  background-image: url("np.186491bc.svg");
}

.fi-nr {
  background-image: url("nr.bd632e6a.svg");
}

.fi-nr.fis {
  background-image: url("nr.c2159ee1.svg");
}

.fi-nu {
  background-image: url("nu.6036347c.svg");
}

.fi-nu.fis {
  background-image: url("nu.84d33a94.svg");
}

.fi-nz {
  background-image: url("nz.ef2edf50.svg");
}

.fi-nz.fis {
  background-image: url("nz.07076364.svg");
}

.fi-om {
  background-image: url("om.962af9eb.svg");
}

.fi-om.fis {
  background-image: url("om.c514721d.svg");
}

.fi-pa {
  background-image: url("pa.41a715d3.svg");
}

.fi-pa.fis {
  background-image: url("pa.172c09b8.svg");
}

.fi-pe {
  background-image: url("pe.ddc323dc.svg");
}

.fi-pe.fis {
  background-image: url("pe.e73b65ef.svg");
}

.fi-pf {
  background-image: url("pf.de1a4843.svg");
}

.fi-pf.fis {
  background-image: url("pf.fc04d0b2.svg");
}

.fi-pg {
  background-image: url("pg.e61f60a7.svg");
}

.fi-pg.fis {
  background-image: url("pg.7be6e02a.svg");
}

.fi-ph {
  background-image: url("ph.810d16d3.svg");
}

.fi-ph.fis {
  background-image: url("ph.dcd68106.svg");
}

.fi-pk {
  background-image: url("pk.eefe276e.svg");
}

.fi-pk.fis {
  background-image: url("pk.b5e20ed4.svg");
}

.fi-pl {
  background-image: url("pl.0b6ff266.svg");
}

.fi-pl.fis {
  background-image: url("pl.0eb42c58.svg");
}

.fi-pm {
  background-image: url("pm.0b9edb0c.svg");
}

.fi-pm.fis {
  background-image: url("pm.01e12f37.svg");
}

.fi-pn {
  background-image: url("pn.1b5a7e93.svg");
}

.fi-pn.fis {
  background-image: url("pn.08d92908.svg");
}

.fi-pr {
  background-image: url("pr.24265870.svg");
}

.fi-pr.fis {
  background-image: url("pr.d7018a23.svg");
}

.fi-ps {
  background-image: url("ps.7ed79ce8.svg");
}

.fi-ps.fis {
  background-image: url("ps.9739e766.svg");
}

.fi-pt {
  background-image: url("pt.de91179c.svg");
}

.fi-pt.fis {
  background-image: url("pt.457b52d5.svg");
}

.fi-pw {
  background-image: url("pw.1b1acdd8.svg");
}

.fi-pw.fis {
  background-image: url("pw.ed4f5c52.svg");
}

.fi-py {
  background-image: url("py.82f8230b.svg");
}

.fi-py.fis {
  background-image: url("py.7c639d31.svg");
}

.fi-qa {
  background-image: url("qa.5411fdef.svg");
}

.fi-qa.fis {
  background-image: url("qa.5df85127.svg");
}

.fi-re {
  background-image: url("re.c7691d29.svg");
}

.fi-re.fis {
  background-image: url("re.12f765f8.svg");
}

.fi-ro {
  background-image: url("ro.1d1b41d9.svg");
}

.fi-ro.fis {
  background-image: url("ro.0702b4f8.svg");
}

.fi-rs {
  background-image: url("rs.8d10af8f.svg");
}

.fi-rs.fis {
  background-image: url("rs.5577f1c4.svg");
}

.fi-ru {
  background-image: url("ru.9f04f01b.svg");
}

.fi-ru.fis {
  background-image: url("ru.5718321b.svg");
}

.fi-rw {
  background-image: url("rw.38216f96.svg");
}

.fi-rw.fis {
  background-image: url("rw.0fc23471.svg");
}

.fi-sa {
  background-image: url("sa.d68414c7.svg");
}

.fi-sa.fis {
  background-image: url("sa.9ff98428.svg");
}

.fi-sb {
  background-image: url("sb.727f45c9.svg");
}

.fi-sb.fis {
  background-image: url("sb.b3b2bb8b.svg");
}

.fi-sc {
  background-image: url("sc.d5a39d66.svg");
}

.fi-sc.fis {
  background-image: url("sc.b2597cb3.svg");
}

.fi-sd {
  background-image: url("sd.989097b6.svg");
}

.fi-sd.fis {
  background-image: url("sd.e26a29d2.svg");
}

.fi-se {
  background-image: url("se.ae03fa34.svg");
}

.fi-se.fis {
  background-image: url("se.c3d8d39b.svg");
}

.fi-sg {
  background-image: url("sg.7120c3ec.svg");
}

.fi-sg.fis {
  background-image: url("sg.3cd106ec.svg");
}

.fi-sh {
  background-image: url("sh.432d50a0.svg");
}

.fi-sh.fis {
  background-image: url("sh.3159baa4.svg");
}

.fi-si {
  background-image: url("si.4eceab81.svg");
}

.fi-si.fis {
  background-image: url("si.942112d6.svg");
}

.fi-sj {
  background-image: url("sj.d71460f6.svg");
}

.fi-sj.fis {
  background-image: url("sj.378d4f6e.svg");
}

.fi-sk {
  background-image: url("sk.60c31c73.svg");
}

.fi-sk.fis {
  background-image: url("sk.30aa5731.svg");
}

.fi-sl {
  background-image: url("sl.797415d8.svg");
}

.fi-sl.fis {
  background-image: url("sl.d4b103d1.svg");
}

.fi-sm {
  background-image: url("sm.86da85ce.svg");
}

.fi-sm.fis {
  background-image: url("sm.402e8eb6.svg");
}

.fi-sn {
  background-image: url("sn.4abea7a5.svg");
}

.fi-sn.fis {
  background-image: url("sn.660bd8b6.svg");
}

.fi-so {
  background-image: url("so.96ed0a57.svg");
}

.fi-so.fis {
  background-image: url("so.1cfc344b.svg");
}

.fi-sr {
  background-image: url("sr.d382bf50.svg");
}

.fi-sr.fis {
  background-image: url("sr.8b70e210.svg");
}

.fi-ss {
  background-image: url("ss.ae156d62.svg");
}

.fi-ss.fis {
  background-image: url("ss.5fc32857.svg");
}

.fi-st {
  background-image: url("st.ce274a4e.svg");
}

.fi-st.fis {
  background-image: url("st.ee922861.svg");
}

.fi-sv {
  background-image: url("sv.1a711764.svg");
}

.fi-sv.fis {
  background-image: url("sv.10c09b1a.svg");
}

.fi-sx {
  background-image: url("sx.8530976a.svg");
}

.fi-sx.fis {
  background-image: url("sx.57633986.svg");
}

.fi-sy {
  background-image: url("sy.5ac745ba.svg");
}

.fi-sy.fis {
  background-image: url("sy.d8c0d4dd.svg");
}

.fi-sz {
  background-image: url("sz.5cc40387.svg");
}

.fi-sz.fis {
  background-image: url("sz.00c2ca5c.svg");
}

.fi-tc {
  background-image: url("tc.eac8d3a0.svg");
}

.fi-tc.fis {
  background-image: url("tc.bb5211cf.svg");
}

.fi-td {
  background-image: url("td.715ec2da.svg");
}

.fi-td.fis {
  background-image: url("td.61e6da32.svg");
}

.fi-tf {
  background-image: url("tf.0ceab934.svg");
}

.fi-tf.fis {
  background-image: url("tf.8c60f3ba.svg");
}

.fi-tg {
  background-image: url("tg.67c97a2b.svg");
}

.fi-tg.fis {
  background-image: url("tg.35a6b36e.svg");
}

.fi-th {
  background-image: url("th.2394435a.svg");
}

.fi-th.fis {
  background-image: url("th.4c172ad3.svg");
}

.fi-tj {
  background-image: url("tj.382d1c1b.svg");
}

.fi-tj.fis {
  background-image: url("tj.ea1a0158.svg");
}

.fi-tk {
  background-image: url("tk.2c03e7ba.svg");
}

.fi-tk.fis {
  background-image: url("tk.590b8dda.svg");
}

.fi-tl {
  background-image: url("tl.936accd9.svg");
}

.fi-tl.fis {
  background-image: url("tl.9e916981.svg");
}

.fi-tm {
  background-image: url("tm.93961e8e.svg");
}

.fi-tm.fis {
  background-image: url("tm.39c6f137.svg");
}

.fi-tn {
  background-image: url("tn.289fa180.svg");
}

.fi-tn.fis {
  background-image: url("tn.be3b600d.svg");
}

.fi-to {
  background-image: url("to.ca2f4916.svg");
}

.fi-to.fis {
  background-image: url("to.14352cef.svg");
}

.fi-tr {
  background-image: url("tr.c80e006f.svg");
}

.fi-tr.fis {
  background-image: url("tr.731d3f70.svg");
}

.fi-tt {
  background-image: url("tt.a65de216.svg");
}

.fi-tt.fis {
  background-image: url("tt.5f9ad635.svg");
}

.fi-tv {
  background-image: url("tv.2602c780.svg");
}

.fi-tv.fis {
  background-image: url("tv.4b0ebb2b.svg");
}

.fi-tw {
  background-image: url("tw.8a8ed4da.svg");
}

.fi-tw.fis {
  background-image: url("tw.2b8ef12f.svg");
}

.fi-tz {
  background-image: url("tz.36f63d04.svg");
}

.fi-tz.fis {
  background-image: url("tz.ef94356c.svg");
}

.fi-ua {
  background-image: url("ua.a4bcc5a3.svg");
}

.fi-ua.fis {
  background-image: url("ua.3b8bdc6c.svg");
}

.fi-ug {
  background-image: url("ug.4b956675.svg");
}

.fi-ug.fis {
  background-image: url("ug.3375e120.svg");
}

.fi-um {
  background-image: url("um.06f58baa.svg");
}

.fi-um.fis {
  background-image: url("um.0681de85.svg");
}

.fi-us {
  background-image: url("us.2973a08f.svg");
}

.fi-us.fis {
  background-image: url("us.e252def8.svg");
}

.fi-uy {
  background-image: url("uy.31768c99.svg");
}

.fi-uy.fis {
  background-image: url("uy.8852c47f.svg");
}

.fi-uz {
  background-image: url("uz.4abe5e5f.svg");
}

.fi-uz.fis {
  background-image: url("uz.03a9a182.svg");
}

.fi-va {
  background-image: url("va.14964bd9.svg");
}

.fi-va.fis {
  background-image: url("va.2f6d31f2.svg");
}

.fi-vc {
  background-image: url("vc.7445a208.svg");
}

.fi-vc.fis {
  background-image: url("vc.24c9fce5.svg");
}

.fi-ve {
  background-image: url("ve.e206d140.svg");
}

.fi-ve.fis {
  background-image: url("ve.ffcbd95b.svg");
}

.fi-vg {
  background-image: url("vg.2fad34db.svg");
}

.fi-vg.fis {
  background-image: url("vg.3d16651a.svg");
}

.fi-vi {
  background-image: url("vi.91047673.svg");
}

.fi-vi.fis {
  background-image: url("vi.d78113c5.svg");
}

.fi-vn {
  background-image: url("vn.67d4ef00.svg");
}

.fi-vn.fis {
  background-image: url("vn.fdadb6f4.svg");
}

.fi-vu {
  background-image: url("vu.834692d2.svg");
}

.fi-vu.fis {
  background-image: url("vu.fe81406c.svg");
}

.fi-wf {
  background-image: url("wf.bdbf9890.svg");
}

.fi-wf.fis {
  background-image: url("wf.73114ac1.svg");
}

.fi-ws {
  background-image: url("ws.8bc0c110.svg");
}

.fi-ws.fis {
  background-image: url("ws.a3a7de00.svg");
}

.fi-ye {
  background-image: url("ye.cf3f3b0d.svg");
}

.fi-ye.fis {
  background-image: url("ye.24e9ec61.svg");
}

.fi-yt {
  background-image: url("yt.09eac0f5.svg");
}

.fi-yt.fis {
  background-image: url("yt.4dcfd1cb.svg");
}

.fi-za {
  background-image: url("za.b9864594.svg");
}

.fi-za.fis {
  background-image: url("za.683111c0.svg");
}

.fi-zm {
  background-image: url("zm.de6994c9.svg");
}

.fi-zm.fis {
  background-image: url("zm.1ee4797a.svg");
}

.fi-zw {
  background-image: url("zw.f912df2c.svg");
}

.fi-zw.fis {
  background-image: url("zw.19181607.svg");
}

.fi-ac {
  background-image: url("ac.a32f432c.svg");
}

.fi-ac.fis {
  background-image: url("ac.43777029.svg");
}

.fi-arab {
  background-image: url("arab.14878a0d.svg");
}

.fi-arab.fis {
  background-image: url("arab.a9236391.svg");
}

.fi-cefta {
  background-image: url("cefta.52e37af7.svg");
}

.fi-cefta.fis {
  background-image: url("cefta.b2f4fda3.svg");
}

.fi-cp {
  background-image: url("cp.383ac63b.svg");
}

.fi-cp.fis {
  background-image: url("cp.85bad092.svg");
}

.fi-dg {
  background-image: url("dg.d6ab7301.svg");
}

.fi-dg.fis {
  background-image: url("dg.cf9f10ff.svg");
}

.fi-eac {
  background-image: url("eac.6c6b014a.svg");
}

.fi-eac.fis {
  background-image: url("eac.0b8bc01d.svg");
}

.fi-es-ct {
  background-image: url("es-ct.b6a369ef.svg");
}

.fi-es-ct.fis {
  background-image: url("es-ct.71b6b65c.svg");
}

.fi-es-ga {
  background-image: url("es-ga.733621b6.svg");
}

.fi-es-ga.fis {
  background-image: url("es-ga.b0634371.svg");
}

.fi-es-pv {
  background-image: url("es-pv.d685f1d8.svg");
}

.fi-es-pv.fis {
  background-image: url("es-pv.2dd96646.svg");
}

.fi-eu {
  background-image: url("eu.cbc3c2b0.svg");
}

.fi-eu.fis {
  background-image: url("eu.6aa3012a.svg");
}

.fi-gb-eng {
  background-image: url("gb-eng.f2eebb0a.svg");
}

.fi-gb-eng.fis {
  background-image: url("gb-eng.f678238e.svg");
}

.fi-gb-nir {
  background-image: url("gb-nir.aee1b16b.svg");
}

.fi-gb-nir.fis {
  background-image: url("gb-nir.9c733b13.svg");
}

.fi-gb-sct {
  background-image: url("gb-sct.941ae258.svg");
}

.fi-gb-sct.fis {
  background-image: url("gb-sct.d171fb92.svg");
}

.fi-gb-wls {
  background-image: url("gb-wls.092a4724.svg");
}

.fi-gb-wls.fis {
  background-image: url("gb-wls.fa13b19f.svg");
}

.fi-ic {
  background-image: url("ic.00cf8236.svg");
}

.fi-ic.fis {
  background-image: url("ic.3297fb35.svg");
}

.fi-ta {
  background-image: url("ta.216619bf.svg");
}

.fi-ta.fis {
  background-image: url("ta.67f29767.svg");
}

.fi-un {
  background-image: url("un.430065c9.svg");
}

.fi-un.fis {
  background-image: url("un.5e9c981d.svg");
}

.fi-xk {
  background-image: url("xk.7ac6491b.svg");
}

.fi-xk.fis {
  background-image: url("xk.f5cf93b7.svg");
}

/*# sourceMappingURL=index.b3f91133.css.map */
